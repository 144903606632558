const uswds = window.uswds = require("../../node_modules/@uswds/uswds/packages/uswds-core/src/js");

$(document).ready(function(){
    if($(".pbindex .pbindex-event").length > 0 ){
        const pbbuttons = document.querySelectorAll(".pbindex .usa-accordion__button");
        const buttonExpand = document.querySelector('#pbindex-button-expand');
        const buttonCollapse = document.querySelector('#pbindex-button-collapse');
        const buttonToggle = document.querySelector('#pbindex-button-toggle');

        buttonExpand && buttonExpand.addEventListener("click", setExpanded);
        buttonCollapse && buttonCollapse.addEventListener("click", setClosed);
        buttonToggle && buttonToggle.addEventListener("click", toggle);
        function setExpanded() {
            // let pbbuttons = document.querySelectorAll(".pbindex .usa-accordion__button");
            pbbuttons.forEach((element) => uswds.accordion.toggle(element, true)) ;
            console.log("expanding "+pbbuttons.length);
        }
        function setClosed() {
            // let pbbuttons = document.querySelectorAll(".pbindex .usa-accordion__button");
            pbbuttons.forEach((element) => uswds.accordion.toggle(element, false)) ;
            console.log("closing " + pbbuttons.length);
        }
        function toggle() {
            // let pbbuttons = document.querySelectorAll(".pbindex .usa-accordion__button");
            pbbuttons.forEach((element) => uswds.accordion.toggle(element)) ;
            console.log("toggling " + pbbuttons.length);
        }
    }   
});
