// Taking hotwire out due to JS issues w/ USWDS, we can optimize this performance later
//import '@hotwired/turbo-rails'
import '@rails/ujs'
import './controllers'

//import * as jq from 'jquery'
window.$ = window.jQuery = require('jquery');
//import './bootstrap'

import "./uswds"
const pb_utils = require("./pb_utils");
const pb_table = require("./pb_table");

// NOTE: KEEP THIS For inline JS script in the HTML
window.importmapScriptsLoaded = true;
var event = new CustomEvent("railsImportmapLoaded", { "detail": "loaded" });
window.dispatchEvent(event);
