$ = jQuery = window.$ = window.jQuery = require("jquery");
var DataTable = (window.Datatable = require("datatables.net")(window, $));
require("datatables.net-searchbuilder-dt")(window, $);
require("datatables.net-searchpanes-dt")(window, $);
require("datatables.net-select-dt")(window, $);
require("datatables.net-buttons-dt")(window, $);
require("datatables.net-buttons/js/buttons.colVis.js")(window, $);
require("datatables.net-buttons/js/buttons.html5.js")(window, $);
require("datatables.net-buttons/js/buttons.print.js")(window, $);
require("datatables.net-responsive-dt")(window, $);
require("jszip");
require("pdfmake");

const pbtable_id = "#pb-topic-table";
const playbook_json_url = "/docs/playbook.json";

$(document).ready(function () {
    var pb_table;
    if ($(pbtable_id).length > 0) {
        fetch(playbook_json_url)
            .then((resp) => resp.json())
            .then((json) => initTable(json))
            .then((data) => (pb_table = data));
        return pb_table;
    }
});

function onlyUnique(value, index, array) {
    return self.indexOf(value) === index;
}

async function loadPbJSON(loc = "/docs/playbook.json") {
    const response = await fetch(loc);
    return response.json();
}

function getFieldCategories(json, field_name) {
    const fetchField = (item) => item[field_name];
    let allvalues = json.flatMap(fetchField);
    let unique_v = allvalues.filter((value, index, array) => array.indexOf(value) === index);
    return unique_v;
}
function fieldSearchPaneOptions(field_name, values) {
    //{label: 'Cat name', value: func }
    function makehash(x) {
        return {
            label: x,
            value: (rowData, rowIdx) => rowData[field_name].includes(x),
        };
    }
    return values.map(makehash);
}
function makefieldSearchPaneOptions(json, field_name) {
    const cats = getFieldCategories(json, field_name);
    return fieldSearchPaneOptions(field_name, cats);
}

function initTable(json, tableid = pbtable_id) {
    const filter_for_ai_actors = makefieldSearchPaneOptions(json, "AI Actors");
    const filter_for_topics = makefieldSearchPaneOptions(json, "Topic");

    return $(pbtable_id).DataTable({
        // dom: "Plfrtip",
        dom: "PBrti",
        //B : buttons
        //P search panes
        // t table
        //l - length changing input control
        //r - processing display element
        //f - filtering input
        //p - pagination control
        //i - Table information summary
        data: json,
        buttons: ["copy", "pdf", "print", "colvis"],
        columns: [
            {
                data: "type",
                title: "Type",
                searchPanes: {
                    show: true,
                },
                width: "max-content",
            },
            {
                data: "title",
                title: "Title",
                // width: "max-content",
                // width: "5.2em",
                render: function (data, type, row, meta) {
                    // var anchor = data[0].toUpperCase() + data.slice(1).toLowerCase();
                    const cat = row["type"];
                    const title_capitalized = data[0].toUpperCase() + data.slice(1).toLowerCase();
                    const anchor = title_capitalized.replace(" ", "%20");
                    const dataid = `${pbtable_id}-${title_capitalized.replace(" ", "_")}`;
                    const tooltip = row["description"];
                    //<div class="tablet:grid-col-3 margin-top-2 text-center">
                    return `\
                    <a title="${tooltip}" data-position="bottom" id="${dataid}" class="pb-topic-table-link usa-tooltip usa-button usa-button--secondary" href="/AI_RMF_Knowledge_Base/Playbook/${cat}#${anchor}">${title_capitalized}</a>\
                    `;
                    //</div>`;
                    // <div role="tooltip" id="description">${tooltip}</div>`;
                    // return '<a id="'+ "table-" +'" class="usa-link" href="' + "/AI_RMF_Knowledge_Base/Playbook/"+cat+"#"+anchor + '">'+title_capitalized+'</a>';
                },
            },
            {
                data: "AI Actors[, ]",
                title: "AI Actors",
                searchPanes: {
                    options: filter_for_ai_actors,
                    show: true,
                },
                orderable: false,
            },
            {
                data: "Topic[, ]",
                title: "Topics",
                searchPanes: {
                    options: filter_for_topics,
                    show: true,
                },
                orderable: false,
            },
            {
                data: "description",
                title: "Description",
                visible: false,
            },
        ],
        search: {
            smart: true,
        },
        scrollY: "60vh",
        scrollX: true,
        deferRender: true,
        scrollCollapse: true,
        paging: false,
        // scroller: true

        select: true,
        // stateSave: true,
        retrieve: true,
    });
}
